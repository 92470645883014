/**
 * Main project app script!
 *
 * All script functionality should be in this file \
 *
 * // TODO jquery most likely will have to be disabled in WP and bundled in the app.js. I have suspicions that binding $ doesn't work
 **/

// import jQuery from "jQuery";

// prevents platform from removing parent utility imports
import utilities from "Parent/js/utilities/utilities";

utilities;

//Modules
import { AOS } from "Parent/js/modules/modules";

// All generic global site javascript should live in this file.
import Globals from "./globals";
import Locations from "./locations";
import Sliders from "./lib/sliders";
import BetterMenu from "./lib/better-menu";
import SuperHideShow from "./lib/super-hide-show";

// Attach the scripts after the website is up and running
document.addEventListener("DOMContentLoaded", () => {
	// Remove and uncomment the enqueue script in functions.php if not needed

	const globals = new Globals();
	const locations = new Locations();
	const sliders = new Sliders();
	const menu = new BetterMenu(1200);
	const sh_el = document.querySelector("[data-s-hide-show]");
	if (sh_el) {
		const superHideShow = new SuperHideShow(sh_el, { duration: 5000 });
	}
});
