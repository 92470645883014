/**
 * Sort of a slider but not really
 **/

class SuperHideShow {
	constructor(element, options) {
		// Default options with spread overwrite for any custom options
		this.options = {
			duration: 1000,
			loop: true,
			...options,
		};

		// Keep track of all the things~
		this.state = {
			animating: false,
			currentItem: 0,
		};

		this.element = element;

		this.items = [];

		if (this.element.hasAttribute("data-s-hide-show")) {
			this.run();
		} else {
			console.error("SuperHideShow: this isn't setup correctly ");
		}
	}

	// Sets up the hide/show
	run() {
		this.items = this.element.querySelectorAll("[data-s-hs-item]");

		let iterator = 0;
		this.items.forEach((item) => {
			item.setAttribute("data-s-hs-item", iterator);
			item.setAttribute("data-is-active", "false");
			iterator++;
		});

		// Run itemSwitcher once to start the hide-show!
		this.itemSwitcher();
		this.state.animating = true;
		setInterval(this.itemSwitcher.bind(this), this.options.duration);
	}

	itemSwitcher() {
		const prevItemIndex = this.previousItem();
		const nextItemIndex = this.nextItem();

		const nextItem = this.items[nextItemIndex];
		this.items[this.state.currentItem].setAttribute("data-is-active", "false");
		nextItem.setAttribute("data-is-active", "true");

		this.state.currentItem = nextItemIndex;
	}

	/**
	 * Returns the previous item in the hide-show item array
	 * @returns {*}
	 */
	previousItem() {
		const length = this.items.length;
		const previous = this.state.currentItem - 1;

		if (previous < 0) {
			return length - 1;
		} else {
			return previous;
		}
	}

	/**
	 * Returns the next item in the hide-show item array
	 * @returns {*}
	 */
	nextItem() {
		const length = this.items.length;
		const next = this.state.currentItem + 1;

		if (next >= length) {
			return 0;
		} else {
			return next;
		}
	}
}

export default SuperHideShow;
