import axios from "axios";

class Locations {

    constructor() {

        const mapContainer = document.getElementById('mapContainer');

        if(mapContainer) {
            this.loadMarkers();
        }
    }

    loadMarkers() {

    //    const axios = require('axios');

        let data = new FormData;
        data.append('action', 'loadLocationMarkers');

        axios.post("/wp-admin/admin-ajax.php", data).then((response) => {
            if ( response.data ) {

                const mapStyles = [
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#13B8C6"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#E1F6F8"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 45
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#cccccc"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#13B8C6"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    }
                ];
                const svgMarker = {
                    path:
                      "m23.822 3a16.31 16.31 0 0 0 -16.322 16.322c0 12.241 16.322 30.311 16.322 30.311s16.322-18.07 16.322-30.311a16.31 16.31 0 0 0 -16.322-16.322zm0 22.151a5.829 5.829 0 1 1 5.829-5.829 5.831 5.831 0 0 1 -5.829 5.829z",
                    fillColor: "#26235f",
                    fillOpacity: 1,
                    strokeWeight: 0,
                    rotation: 0,
                    scale: .5,
                    anchor: new google.maps.Point(0, 0),
                };

                const map = new google.maps.Map(document.getElementById("mapContainer"), {
                    zoom: 9,
                    center: { lat: 41.280387, lng: -75.496296 },
                    disableDefaultUI: true,
                    styles: mapStyles
                  });

                const infoWindow = new google.maps.InfoWindow();

                let pins = [];

                Object.entries(response.data).forEach(([key, value]) => {
                    pins.push([
                        {lat: parseFloat(value.latitude), lng: parseFloat(value.longitude) },
                        value.html
                    ])
                });

                pins.forEach(([position, title], i) => {
                    const marker = new google.maps.Marker({
                      position,
                      map,
                      title: `${title}`,
                      optimized: false,
                      icon: svgMarker
                    });
                    // Add a click listener for each marker, and set up the info window.
                    marker.addListener("click", () => {
                      infoWindow.close();
                      infoWindow.setContent(marker.getTitle());
                      infoWindow.open(marker.getMap(), marker);
                    });
                  });


            }
        });
    }
}

export default Locations;
