import "swiper/swiper-bundle.min.css";

import Swiper, {
	Navigation,
	Pagination,
	Scrollbar,
	Virtual,
	Keyboard,
	Mousewheel,
	Parallax,
	Zoom,
	Lazy,
	Controller,
	A11y,
	History,
	HashNavigation,
	Autoplay,
	EffectFade,
	EffectCube,
	EffectFlip,
	EffectCoverflow,
	Thumbs,
} from "swiper";

export default Swiper;
export {
	Navigation,
	Pagination,
	Scrollbar,
	Virtual,
	Keyboard,
	Mousewheel,
	Parallax,
	Zoom,
	Lazy,
	Controller,
	A11y,
	History,
	HashNavigation,
	Autoplay,
	EffectFade,
	EffectCube,
	EffectFlip,
	EffectCoverflow,
	Thumbs,
};
