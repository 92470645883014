/**
 * Imports the safari/old-browser polyfill
 * The polyfill needs to be initialized first and then
 * a small helper library hooks the polyfill into the css property `scroll-behavior: smooth`
 *
 * To be noted:
 * This can be inline styled on the html element OR in SCSS with:
 *
 * html {
 *   --scroll-behavior: smooth;
 *   scroll-behavior: smooth;
 * }
 *
 * the `--scroll-behavior` is needed to allow the JS polyfill to see the element which should be smooth scroll
 * (This is already implemented in both places on the framework)
 * Either one can be removed if needed :)
 **/
 import smoothscroll from 'smoothscroll-polyfill';

 // kick off the polyfill!
 smoothscroll.polyfill();
 import 'smoothscroll-anchor-polyfill';

// Jank way to make sure this file does not get treeshaked
const utilities = {};

export default utilities;