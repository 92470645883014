import Swiper, { Navigation, Pagination, Lazy } from "Parent/js/modules/swiper/swiper";

Swiper.use([Navigation, Pagination, Lazy]);

class Sliders {
	constructor() {
		this.testimonials_slider_el = document.querySelector(".testimonials-slider");
		this.testimonials_slider = null;

		this.insurance_slider_el = document.querySelector(".insurance-slider");
		this.insurance_slider = null;

		this.location_slider_el = document.querySelector(".location-slider");
		this.location_slider = null;

		if (this.testimonials_slider_el) {
			this.runTestSlider();
		}

		if (this.insurance_slider_el) {
			this.runInsuranceSlider();
		}

		if (this.location_slider_el) {
			this.runLocationSlider();
		}
	}

	runTestSlider() {
		this.testimonials_slider = new Swiper(this.testimonials_slider_el, {
			slidesPerView: 1,
			spaceBetween: 20,
			breakpoints: {
				768: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	}

	runInsuranceSlider() {
		this.insurance_slider = new Swiper(this.insurance_slider_el, {
			slidesPerView: 2,
			preloadImages: false,
			spaceBetween: 20,
			breakpoints: {
				400: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 4,
				},
				1024: {
					slidesPerView: 5,
				},
				1200: {
					slidesPerView: 6,
				},
			},
			lazy: {
				loadPrevNext: true,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	}

	runLocationSlider() {
		this.testimonials_slider = new Swiper(this.location_slider_el, {
			slidesPerView: 1,
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
		});
	}
}

export default Sliders;
